import { initAccordion } from './сomponents/initAccordion';
import { initSliders } from './сomponents/initSliders';
import { initStickyTableHeader } from './сomponents/initStickyTableHeader';
import { initScrollToAnchor } from './сomponents/initScrollToAnchor';
import { initRenderPricesPage } from './сomponents/initRenderPricesPage';
import { initForms } from './сomponents/initForms';
import { initMenus } from './сomponents/initMenus';
import { initModals } from './сomponents/initModals';
import { initAmplitude } from './сomponents/initAmplitude';
import { InitRating } from './сomponents/initRating';
import { initJivosite } from './сomponents/initJivosite';
import { initChunkPageBeforeCheckout } from './сomponents/initChunkPageBeforeCheckout';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initScrollLocker } from '../../../../Common/Resources/src_front/js/components/initScrollLocker';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';
import { initRippleAnimation } from '../../../../Common/Resources/templates/components/RippleAnimation/initRippleAnimation';
import { initPageLoader } from '../../../../Common/Resources/templates/components/PageLoader/initPageLoader';
import { initEmailAutocompleteDropdown } from '../../../../Common/Resources/templates/components/EmailAutocompleteDropdown/initEmailAutocompleteDropdown';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initFetchCheckoutAssetsAndCaching } from '../../../../Common/Resources/src_front/js/components/initFetchCheckoutAssetsAndCaching';

window.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

window.addEventListener('load', function () {
    initFetchCheckoutAssetsAndCaching();
});

// If the browser has cached the page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        window.location.reload();
    }
});

function handleOnLoadPage() {
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initPageLoader();
    initChunkPageBeforeCheckout();
    initHashEmail();
    initScrollLocker();
    initAccordion();
    initSliders();
    initMenus();
    initStickyTableHeader();
    initScrollToAnchor();
    initRenderPricesPage();
    initForms();
    initAmplitude();
    initRippleAnimation();
    initEmailAutocompleteDropdown();
    const initRating = new InitRating();
    initModals(initRating);
    initJivosite();
}
